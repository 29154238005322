import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className='bg-[#f4f1e9] pb-11'>
      
      {/* Hero Section */}
      <div className="relative h-[250px] md:h-[500px]">
        <img
          src="assets/arch-with-hanging-pampas-decor-picnic-background-datesaverco-dmv.webp"
          alt="For You Picnic"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40 flex w-full">
          <h1 className="text-white font-the-seasons text-lg md:text-6xl md:text-left text-center flex items-end pl-5 pb-24 md:items-center md:pl-16 md:pt-44">
            Terms<span className='font-columbia-serial font-thin'>&nbsp;&&nbsp;</span>Conditions
          </h1>
        </div>
      </div>
      <div className="space-y-8 text-[#342b29] text-left md:items-start md:pl-[5%] lg:pl-[20%] mx-5 items-center pt-11 md:pt-16">
      {/*<div className="space-y-8 text-[#342b29] flex flex-col justify-center items-center text-left md:items-start md:pl-[5%] lg:pl-[30%]">*/}
      <h2 className="md:text-3xl text-lg font-columbia-serial">Date Saver Co-Terms & Conditions<br/> 
      <span className="">Effective Date</span>: September 7, 2024</h2>
      <div className="text-[#342b29] text-left md:items-start md:pl-[5%] lg:pl-[3.25%]">
      <div className="font-columbia-serial">
        <p className="">
        We are so excited to welcome you, a dear friend of Date Saver Co. Please read these Terms and Conditions carefully, as they govern your<br/> 
        use of our services and products. By booking with us and checking the box saying “I have read and agree to the terms above” at check-out,<br/> 
        you agree to comply with these terms.
        </p>
        </div>
          </div>
      {/*1st Block of Text*/}
      <div className="font-columbia-serial">
        <h1 className="font-semibold font-columbia-serial text-left md:items-start md:pl-[5%] lg:pl-[3.25%]">I. Booking and Payment</h1>
       </div>

       <div className="font-columbia-serial space-y-1">
       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Retainer Fee</span>: A non-refundable retainer fee of 50% of the total cost is required at the time of booking to secure your event date </li>
       </ul>
       <div className="text-left md:items-start md:pl-[5%] lg:pl-[5.25%]">
        <p><span className="mr-2 text-lg">○</span><span className="italic">Why We Charge a Retainer Fee</span>: It helps us reserve your date, start planning your event, and cover initial costs. It’s deducted from<br/>
        your final payment and ensures we can provide the best service for your special day. Thanks for your understanding!</p>
       </div>

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Final Payment</span>: The remaining balance will be automatically charged to the same payment method 48 hours before your event. In case<br/> 
        there's an issue processing the payment, we’ll reach out to help resolve it, but please note that if it's not sorted, your booking may <br/>
        be canceled.</li>
       </ul>

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Methods of Payment</span>: We accept the following as methods of payment - Visa, Mastercard, American Express, Discover, Diner’s Club, JCB<br/> 
        and PayPal.</li>
       </ul>
       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Additional Charges after booking</span>: Any add-ons or customizations requested after booking will be billed separately. You will be notified<br/> 
        of these charges prior to the event, and these will be billed 48 hours before the scheduled date along with the remaining balance.</li>
       </ul>
       </div>

       {/*2nd Block of Text*/}
       <h1 className="font-semibold font-columbia-serial text-left md:items-start md:pl-[5%] lg:pl-[3.25%]">II. Cancellations & Rescheduling</h1>
       <div className="font-columbia-serial space-y-1">

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Cancellation by Client</span>:</li>
       </ul>
       <div className="text-left md:items-start md:pl-[5%] lg:pl-[5.25%]">
        <p><span className="mr-2 text-lg">○</span><span className="italic">Less Than 48 Hour Notice</span>: Cancellations with less than a 48 hour notice prior to the event, or bookings made 2 days prior to the<br/>
        event are not eligible for a refund.</p>
       </div>

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Rescheduling</span>: We understand that unexpected things happen! Just let us know that you’d like to reschedule as soon as possible! <span className="italic">Subject<br/> 
        to availability</span></li>
       </ul>

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Cancellation by Date Saver Co</span>: In the rare event that Date Saver Co needs to cancel or reschedule due to unforeseen circumstances,<br/>
        you will be notified as soon as possible, and a new date will be offered, or a refund will be provided.</li>
       </ul>
       </div>

       {/*3rd Block of Text*/}
       <h1 className="font-semibold font-columbia-serial text-left md:items-start md:pl-[5%] lg:pl-[3.25%]">III. Weather Policy</h1>
       <div className="font-columbia-serial space-y-1">

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Inclement Weather</span>: In the case of strong winds, rain or unsafe weather conditions, Date Saver Co may not be able to set up certain items<br/>
        such as umbrellas, cabanas, arches or hanging decor for the safety of our guests. We will work with you to find a solution, such as<br/>
        rescheduling or adjusting the setup, but please note that certain weather conditions may limit the experience. We strongly recommend<br/>
        having an indoor location in mind as a back-up in case of sudden weather changes.
        </li>
       </ul>

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Weather Insurance ($95)</span>:
        </li>
       </ul>

       {/*<div className="text-left md:items-start md:pl-[5%] lg:pl-[6.25%]">*/}
        <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[7.5%]">
        <li>If the weather doesn’t cooperate, we’ll reschedule to a date that works for you. Add weather insurance for $95 at checkout to<br/>
        cancel last minute due to weather and get a full refund, including your retainer.
        </li>
        <li>Note: the $95 insurance fee is non-refundable.</li>
        </ul>
       {/*</div>*/}
       </div>


       {/*4th Block of Text*/}
       <h1 className="font-semibold font-columbia-serial text-left md:items-start md:pl-[5%] lg:pl-[3.25%]">IV. Set-up & Take-down</h1>
       <div className="font-columbia-serial space-y-1">

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Set-up time</span>: Our team will arrive about 3 hours prior to the start of your appointment to set up, subject to guest count or any add-ons
        </li>
       </ul>

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Takedown Time</span>: At the end of your appointment, we will return to quietly collect our equipment. Please note that all items provided by<br/>
        Date Saver Co. (e.g., decor, furniture) will be removed.
        </li>
       </ul>

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Client responsibility</span>: It is the client’s responsibility to ensure that private locations are accessible for setup and takedown at the agreed-<br/>
        upon times. If there are delays, and you would wish to extend your time, just give us a call or approach any of our team members at the<br/>
        event. Appointment time extensions are subject to availability and specific charges.
        </li>
       </ul>
       </div>


       {/*5th Block of Text*/}
       <h1 className="font-semibold font-columbia-serial text-left md:items-start md:pl-[5%] lg:pl-[3.25%]">V. Service Modifications</h1>
       <div className="font-columbia-serial space-y-1">

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Need More Time</span>: If you’d like to extend your time at the event, just let us know by calling us or talking to any team member on-site.<br/>
        Additional charges will apply based on the service.
        </li>
       </ul>

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Themes and Decor</span>: We offer a range of pre-planned themes for easy selection. If you have a specific vision, we’re happy to collaborate<br/>
        and bring your ideas to life, while keeping logistical considerations in mind. Please note, any add-ons requested after checkout will be<br/>
        billed separately after the remaining balance is charged.
        </li>
       </ul>
       </div>

       {/*6th Block of Text*/}
       <h1 className="font-semibold font-columbia-serial text-left md:items-start md:pl-[5%] lg:pl-[3.25%]">VI. Privacy & Personal Information</h1>
       <div className="font-columbia-serial space-y-1">

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Data Collection</span>: Date Saver Co. collects personal information for the purpose of processing your booking and delivering services. We will<br/>
        not share your information with third parties without your consent, except as required to fulfill your event needs.
        </li>
       </ul>

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li><span className="underline">Photos & Social Media</span>: By booking with Date Saver Co., you agree that we may take photos of the event setup for promotional purposes.<br/>
        If you do not wish for your event to be photographed, please inform us at the time of booking.
        </li>
       </ul>
       </div>


       {/*7th Block of Text*/}
       <h1 className="font-semibold font-columbia-serial text-left md:items-start md:pl-[5%] lg:pl-[3.25%]">VII. Force Majeure</h1>
       <div className="font-columbia-serial space-y-1">

       <ul className="list-disc text-left md:items-start md:pl-[5%] lg:pl-[4.75%]">
        <li>Date Saver Co. will not be liable for any failure to perform or delay in performance due to causes beyond our control, such as natural<br/>
        disasters, government restrictions, or other unforeseeable events. In such cases, we will work with you to reschedule the event.
        </li>
       </ul>
       </div>

       {/*Last Bits*/}
       <div className="">
       <p className="font-columbia-serial text-left md:items-start md:pl-[5%] lg:pl-[3.25%]">
       If you have any questions regarding the Terms and Conditions, feel free to reach out by phone, email or message us using the form below.
       </p>
       </div>
       <div className="font-columbia-serial text-left md:items-start md:pl-[30%] lg:pl-[30%]">
        <p className="">
        Email: datesaverco@gmail.com</p>
        <p className="text-left md:items-start md:pl-[25%] lg:pl-[3.5%]">
        Phone: (240)716-2816
        </p>      
       </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
